import React, { useCallback } from 'react';

import { UserID } from '../../../../users/usersTypes';
import {
  FetchLifestylesFilters,
  ChangeLifestylesFiltersFunc
} from '../../../lifestylesTypes';

import { Translate } from '../../../../../helpers/Translate';
import { ClientsSelectFilter } from '../../../../../helpers/filters/ClientsSelectFilter';

import { categoriesKeys } from '../../../../../locales/keys';

interface LifestylesClientFilterProps {
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const name = 'clientIds' as const;

function LifestylesClientFilter({
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesClientFilterProps) {
  const handleChange = useCallback<(ids: UserID[]) => void>(
    (ids) => {
      changeLifestylesFilters({ [name]: ids }, ids ? undefined : [name]);
    },
    [changeLifestylesFilters]
  );

  const filterValue = lifestylesFilters?.[name];

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.client.select} />
        </h6>
      </div>
      <div className="flex flex-col space-y-2">
        <ClientsSelectFilter onChange={handleChange} value={filterValue} />
      </div>
    </div>
  );
}

export default LifestylesClientFilter;
