import {
  FetchLifestyleCategoriesCacheKey,
  FetchFinLifestyleCategoriesFilters
} from '../../lifestylesTypes';

import {
  FetchLifestyleStylesQueryResponse,
  FETCH_LIFESTYLE_STYLES_QUERY
} from '../../queries/fetchLifestyleStyles.query';

import { useFinPaginatedCategories } from '../../../categories/hooks/useFinPaginatedCategories';

import { FetchCategoriesScopes } from '../../../categories/categoriesTypes';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.SCENE_STYLES }
};
const options = {
  staleTime: 1000 * 60 * 60
};

interface LifestyleStylesDefaultOptions {
  cacheKey: FetchLifestyleCategoriesCacheKey;
  addInitialFilters?: FetchFinLifestyleCategoriesFilters;
}

function useLifestyleStyles({
  cacheKey,
  addInitialFilters
}: LifestyleStylesDefaultOptions) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const {
    categories,
    categoriesError,
    categoriesFetched,
    categoriesIsPlaceholderData
  } = useFinPaginatedCategories<FetchLifestyleStylesQueryResponse>({
    cacheKey,
    query: FETCH_LIFESTYLE_STYLES_QUERY,
    initialFilters,
    options
  });

  return {
    lifestyleStyles: categories,
    lifestyleStylesErrorMessage: categoriesError,
    lifestyleStylesFetched: categoriesFetched,
    lifestyleStylesIsPlaceholderData: categoriesIsPlaceholderData
  };
}

export default useLifestyleStyles;
