import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import take from 'lodash/take';
import size from 'lodash/size';

import { LifestyleClientID } from '../../lifestylesTypes';

const WRITE_FIRST_IDS_COUNT = 3;

interface GetLifestyleClientIdsCacheKeyPartOptions {
  clientIds: LifestyleClientID[];
}

function getLifestyleClientIdsCacheKeyPart({
  clientIds
}: GetLifestyleClientIdsCacheKeyPartOptions): string {
  if (!isArray(clientIds) || isEmpty(clientIds)) {
    return '';
  }

  const firstThree = take(clientIds, WRITE_FIRST_IDS_COUNT).join('-');

  const totalClientIdsCount = size(clientIds);
  const restCount =
    totalClientIdsCount > WRITE_FIRST_IDS_COUNT
      ? `and-${totalClientIdsCount - 3}-more`
      : null;

  return restCount ? [firstThree, restCount].join('-') : firstThree;
}

export default getLifestyleClientIdsCacheKeyPart;
