import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { OnLightboxOpenAction } from '../../../../../../../helpers/LightboxWrapper';

import { ButtonHelper } from '../../../../../../../helpers/buttons/ButtonHelper';
import { Icon } from '../../../../../../../helpers/Icon';
import {
  ImageHelper,
  ImageItemImageVersions
} from '../../../../../../../helpers/ImageHelper';

import { Files } from '../../../../../../../utils/Files';

const srcSetVersions = {
  '1x': ImageItemImageVersions.MiniThumb48x48,
  '2x': ImageItemImageVersions.MiniThumb96x96,
  '3x': ImageItemImageVersions.MiniThumb144x144
};

import { SelectedLifestylesListItemPreviewSelectedLifestyle } from './SelectedLifestylesListItemPreview.types';

interface SelectedLifestylesListItemPreviewProps {
  selectedLifestyle: SelectedLifestylesListItemPreviewSelectedLifestyle;
  onLightboxOpen: OnLightboxOpenAction;
}

function SelectedLifestylesListItemPreview({
  selectedLifestyle,
  onLightboxOpen
}: SelectedLifestylesListItemPreviewProps) {
  const lifestyle = selectedLifestyle.lifestyle;

  const handleOpenLightbox = useCallback(() => {
    if (lifestyle?.image) {
      onLightboxOpen(lifestyle?.image);
    }
  }, [lifestyle, onLightboxOpen]);

  return (
    <div className="aspect-h-1 flex-shrink-0 h-12 overflow-hidden relative rounded-md w-12">
      {lifestyle?.image && Files.isImage(lifestyle?.image?.file) ? (
        <ButtonHelper className="absolute inset-0" onClick={handleOpenLightbox}>
          <ImageHelper
            className="h-12 w-12"
            src={lifestyle.image.file}
            version={ImageItemImageVersions.MiniThumb48x48}
            srcSetVersions={srcSetVersions}
            alt={lifestyle.name}
            loading="lazy"
          />
        </ButtonHelper>
      ) : (
        <div className="flex items-center justify-center text-gray-600 absolute inset-0">
          <Icon icon={IconsEnum.PHOTOGRAPH_OUTLINE} className="h-12 w-12" />
        </div>
      )}
    </div>
  );
}

export default SelectedLifestylesListItemPreview;
