import React, { useMemo } from 'react';

import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';

import { useLifestyleColors } from '../../../hooks/useLifestyleColors';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { LifestyleCache } from '../../../LifestyleCache';

interface LifestylesColorsFilterBadgeItem {
  id: string;
  label: string;
}

function LifestylesColorsFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const { lifestyleColors } = useLifestyleColors({
    cacheKey: LifestyleCache.colorsFilterCacheKey()
  });

  const LifestylesColorsFilterBadgeItems = useMemo<
    LifestylesColorsFilterBadgeItem[]
  >(() => {
    return lifestyleColors.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [lifestyleColors]);

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.colors.singular}
      items={LifestylesColorsFilterBadgeItems}
      name={name}
      onRemoveFilter={onRemoveFilter}
    />
  );
}

export default LifestylesColorsFilterBadge;
