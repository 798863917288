import React, { memo } from 'react';

import { SelectedLibraryItemsFloatingCounter } from '../../../common/components/SelectedLibraryItemsFloatingCounter';

import {
  UpdateLifestylesSetCacheAction,
  FetchLifestylesSetsCacheKey
} from '../../../lifestylesSets/lifestylesSetsTypes';

import {
  OnSelectedLifestylesSidebarOpenAction,
  OnSelectedLifestylesSidebarCloseAction
} from '../../../lifestyles/lifestylesTypes';

import useSelectedLifestylesFloatingCounter from './useSelectedLifestylesFloatingCounter';

import {
  SelectedLifestylesFloatingCounterLifestylesSet,
  SelectedLifestylesFloatingCounterOnLifestylesSelect
} from './SelectedLifestylesFloatingCounter.types';

interface SelectedLifestylesFloatingCounterProps {
  lifestylesSet: SelectedLifestylesFloatingCounterLifestylesSet;
  lifestylesSetCacheKey?: FetchLifestylesSetsCacheKey;
  isOpen: boolean;
  onOpen: OnSelectedLifestylesSidebarOpenAction;
  onClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<SelectedLifestylesFloatingCounterLifestylesSet>;
  onLifestylesSelect?: SelectedLifestylesFloatingCounterOnLifestylesSelect;
}

function SelectedLifestylesFloatingCounter({
  lifestylesSet,
  lifestylesSetCacheKey,
  isOpen,
  onOpen,
  onClose,
  updateLifestylesSetCache,
  onLifestylesSelect
}: SelectedLifestylesFloatingCounterProps) {
  const {
    selectedLifestylesCount,
    emptyLifestylesSetLoading,
    handleEmptyLifestylesSet
  } = useSelectedLifestylesFloatingCounter({
    lifestylesSet,
    lifestylesSetCacheKey,
    onClose,
    updateLifestylesSetCache,
    onLifestylesSelect
  });

  return (
    <SelectedLibraryItemsFloatingCounter
      itemsCount={selectedLifestylesCount}
      clearLoading={emptyLifestylesSetLoading}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      onClear={handleEmptyLifestylesSet}
    />
  );
}

export default memo<SelectedLifestylesFloatingCounterProps>(
  SelectedLifestylesFloatingCounter
);
