import React from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { FetchLifestylesCacheKeys } from '../../../../../../lifestyles/lifestylesTypes';

import { FetchLifestylesSetsCacheKeys } from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import useSelectedLifestylesListItemLightboxFavoriteButton from './useSelectedLifestylesListItemLightboxFavoriteButton';

import { SelectedLifestylesListItemLightboxFavoriteButtonSelectedLifestyle } from './SelectedLifestylesListItemLightboxFavoriteButton.types';

import { words } from '../../../../../../../locales/keys';

interface SelectedLifestylesListItemLightboxFavoriteButtonProps {
  selectedLifestyle: SelectedLifestylesListItemLightboxFavoriteButtonSelectedLifestyle;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
}

function SelectedLifestylesListItemLightboxFavoriteButton({
  selectedLifestyle,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys
}: SelectedLifestylesListItemLightboxFavoriteButtonProps) {
  const { toggleLifestyleFavoriteLoading, handleToggleFavorite } =
    useSelectedLifestylesListItemLightboxFavoriteButton({
      selectedLifestyle,
      lifestylesCacheKeys,
      lifestylesSetCacheKeys
    });

  return (
    <PureTooltipIconButtonHelper
      className={cl(
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white',
        selectedLifestyle.lifestyle?.favorite ? 'text-pink-600' : null
      )}
      icon={
        selectedLifestyle.lifestyle?.favorite
          ? IconsEnum.HEART_SOLID
          : IconsEnum.HEART_OUTLINE
      }
      iconClassName={cl(
        'h-6 w-6',
        selectedLifestyle.lifestyle?.favorite ? 'p-0.5' : null
      )}
      tooltipSingleton
      tooltipI18nText={
        selectedLifestyle.lifestyle?.favorite
          ? words.removeFromFavorites
          : words.addToFavorites
      }
      disabled={toggleLifestyleFavoriteLoading}
      onClick={handleToggleFavorite}
    />
  );
}

export default SelectedLifestylesListItemLightboxFavoriteButton;
