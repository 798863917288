import React, { useMemo } from 'react';

import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';
import { IdFilterFields } from '../../../../../types';

import { useLifestyleTones } from '../../../hooks/useLifestyleTones';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { LifestyleCache } from '../../../LifestyleCache';

interface LifestylesTonesFilterBadgeItem {
  id: string;
  label: string;
}

function LifestylesTonesFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const { lifestyleTones } = useLifestyleTones({
    cacheKey: LifestyleCache.tonesFilterCacheKey()
  });

  const LifestylesTonesFilterBadgeItems = useMemo<
    LifestylesTonesFilterBadgeItem[]
  >(() => {
    return lifestyleTones.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [lifestyleTones]);

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.tones.singular}
      items={LifestylesTonesFilterBadgeItems}
      name={name}
      onRemoveFilter={onRemoveFilter}
      filterField={IdFilterFields.IN}
    />
  );
}

export default LifestylesTonesFilterBadge;
