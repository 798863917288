import { useCallback } from 'react';
import size from 'lodash/size';
import compact from 'lodash/compact';

import {
  UpdateLifestylesSetCacheAction,
  FetchLifestylesSetsCacheKeys
} from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import { OnSelectedLifestylesSidebarCloseAction } from '../../../../../../lifestyles/lifestylesTypes';

import {
  TOGGLE_LIFESTYLE_IN_LIFESTYLES_SET_QUERY,
  ToggleLifestyleInLifestylesSetQueryResponse
} from '../../../../../../lifestylesSets/queries/toggleLifestyleInLifestylesSet.query';

import { useToggleLifestyleInLifestylesSet } from '../../../../../../lifestylesSets/hooks/useToggleLifestyleInLifestylesSet';

import { useToastNotification } from '../../../../../../../common/hooks/useToastNotification';

import { parseRequestError } from '../../../../../../../utils/parseRequestError';

import { LifestylesSetCache } from '../../../../../../lifestylesSets/LifestylesSetCache';

import {
  SelectedLifestylesListItemDeleteButtonSelectedLifestyle,
  SelectedLifestylesListItemDeleteButtonLifestylesSet
} from './SelectedLifestylesListItemDeleteButton.types';

interface UseSelectedLifestylesListItemDeleteButtonOptions {
  selectedLifestyle: SelectedLifestylesListItemDeleteButtonSelectedLifestyle;
  lifestylesSet: SelectedLifestylesListItemDeleteButtonLifestylesSet;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  onSelectedLifestylesSidebarClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<SelectedLifestylesListItemDeleteButtonLifestylesSet>;
}

function useSelectedLifestylesListItemDeleteButton({
  selectedLifestyle,
  lifestylesSet,
  lifestylesSetCacheKeys,
  onSelectedLifestylesSidebarClose,
  updateLifestylesSetCache
}: UseSelectedLifestylesListItemDeleteButtonOptions) {
  const { toggleLifestyleInLifestylesSet } =
    useToggleLifestyleInLifestylesSet<ToggleLifestyleInLifestylesSetQueryResponse>(
      {
        query: TOGGLE_LIFESTYLE_IN_LIFESTYLES_SET_QUERY,
        cacheKeys: compact([
          LifestylesSetCache.showCacheKey(),
          ...(lifestylesSetCacheKeys || [])
        ]),
        lifestyle: selectedLifestyle.lifestyle,
        updateLifestylesSetCache
      }
    );

  const { showToastNotification } = useToastNotification({
    appearance: 'error'
  });

  const selectedLifestylesAmount = size(lifestylesSet?.selectedLifestyles);

  const handleAfterToggle = useCallback(() => {
    if (selectedLifestylesAmount === 1) {
      onSelectedLifestylesSidebarClose();
    }
  }, [selectedLifestylesAmount, onSelectedLifestylesSidebarClose]);

  const lifestylesSetUuid = lifestylesSet?.uuid;
  const lifestyleId = selectedLifestyle?.lifestyle?.id;

  const handleToggleSelected = useCallback(() => {
    if (!lifestylesSetUuid || !lifestyleId) {
      return;
    }

    toggleLifestyleInLifestylesSet({
      uuid: lifestylesSetUuid,
      lifestyleId
    }).catch((error) => {
      showToastNotification(parseRequestError(error));
    });

    handleAfterToggle();
  }, [
    lifestylesSetUuid,
    lifestyleId,
    toggleLifestyleInLifestylesSet,
    showToastNotification,
    handleAfterToggle
  ]);

  return {
    handleToggleSelected
  };
}

export default useSelectedLifestylesListItemDeleteButton;
