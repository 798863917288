import { useCallback } from 'react';
import { ClientError } from 'graphql-request';
import { useMutation, useQueryClient } from 'react-query';

import { UpdateLifestylesSetCacheAction } from '../../lifestylesSetsTypes';

import {
  EmptyLifestylesSetInput,
  EmptyLifestylesSetError,
  EmptyLifestylesSetResponse,
  action,
  EMPTY_LIFESTYLES_SET_QUERY,
  EmptyLifestylesSetQueryResponse
} from './emptyLifestylesSet.query';

import { updateItem } from '../../../common/hooks/base/reactQuery/baseActions/updateItem';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface EmptyLifestylesSetContext {
  rollback?: () => void;
}

interface EmptyLifestylesSetOptions {
  itemCacheKey: string;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<EmptyLifestylesSetQueryResponse>;
}

function useEmptyLifestylesSet({
  itemCacheKey,
  updateLifestylesSetCache
}: EmptyLifestylesSetOptions) {
  const queryClient = useQueryClient();

  const handleOptimisticUpdate = useCallback<() => null | (() => void)>(() => {
    return updateLifestylesSetCache({
      updateFunction: (prevLifestyleSet) => ({
        ...prevLifestyleSet,
        selectedLifestyles: []
      })
    });
  }, [updateLifestylesSetCache]);

  const { error, isLoading, mutateAsync, reset } = useMutation<
    EmptyLifestylesSetResponse,
    EmptyLifestylesSetError | ClientError | Error,
    EmptyLifestylesSetInput,
    EmptyLifestylesSetContext
  >(
    (queryInput) =>
      updateItem<EmptyLifestylesSetInput, EmptyLifestylesSetResponse>({
        query: EMPTY_LIFESTYLES_SET_QUERY,
        queryInput,
        action
      }),
    {
      onMutate: () => {
        const rollback = handleOptimisticUpdate() || undefined;

        return { rollback };
      },
      onError: (error, variables, context) => {
        context?.rollback?.();
      },
      onSettled: () => {
        if (itemCacheKey) {
          queryClient.invalidateQueries(itemCacheKey);
        }
      }
    }
  );

  return {
    emptyLifestylesSetError: error,
    emptyLifestylesSetLoading: isLoading,
    emptyLifestylesSetErrorMessage: parseRequestError(error),
    emptyLifestylesSet: mutateAsync,
    emptyLifestylesSetReset: reset
  };
}

export default useEmptyLifestylesSet;
