import React, { useCallback, useMemo } from 'react';

import {
  FetchLifestylesFilters,
  ChangeLifestylesFiltersFunc
} from '../../../lifestylesTypes';

import { Translate } from '../../../../../helpers/Translate';
import {
  YesNoAnyRadioButtons,
  YesNoAnyRadioButtonsValue
} from '../../../../../helpers/YesNoAnyRadioButtons';

import { formsFields } from '../../../../../locales/keys';

interface LifestylesBlockedFilterProps {
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const name = 'blocked' as const;

function LifestylesBlockedFilter({
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesBlockedFilterProps) {
  const handleChange = useCallback<(value: YesNoAnyRadioButtonsValue) => void>(
    (value) => {
      switch (value) {
        case YesNoAnyRadioButtonsValue.YES:
          changeLifestylesFilters({ [name]: true });
          break;
        case YesNoAnyRadioButtonsValue.NO:
          changeLifestylesFilters({ [name]: false });
          break;
        case YesNoAnyRadioButtonsValue.ANY:
          changeLifestylesFilters({}, [name]);
          break;
      }
    },
    [changeLifestylesFilters]
  );

  const value = useMemo(() => {
    switch (lifestylesFilters?.[name]) {
      case true:
        return YesNoAnyRadioButtonsValue.YES;
      case false:
        return YesNoAnyRadioButtonsValue.NO;
      default:
        return YesNoAnyRadioButtonsValue.ANY;
    }
  }, [lifestylesFilters]);

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={formsFields.blocked} />
        </h6>
      </div>
      <YesNoAnyRadioButtons name={name} value={value} onChange={handleChange} />
    </div>
  );
}

export default LifestylesBlockedFilter;
