import { gql } from 'graphql-request';

import {
  CategoryID,
  CategoryUUID,
  CategoryName,
  CategoryLocalizedName
} from '../../categories/categoriesTypes';

export interface FetchLifestyleTypesQueryResponse {
  uuid: CategoryUUID;
  id: CategoryID;
  name: CategoryName;
  localizedName: CategoryLocalizedName;
}

export const FETCH_LIFESTYLE_TYPES_QUERY = gql`
  query LifestyleTypesCategories(
    $filters: CategoriesFilters
    $sort: [CategoriesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    categories(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
      nodes {
        uuid
        id
        name
        localizedName
      }
    }
  }
`;
