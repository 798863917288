import React, { useCallback } from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../assets/icons/types';
import { IsDisabled, I18nText, ClassName } from '../../../../types';

import {
  ToggleButtonGroupBaseValue,
  ToggleButtonGroupOnChange
} from '../../ToggleButtonGroup.types';

import { PureIconButtonHelper } from '../../../buttons/PureIconButtonHelper';

interface ToggleButtonGroupItemProps<T extends ToggleButtonGroupBaseValue> {
  value: T;
  selectedValue: T;
  disabled?: IsDisabled;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  onChange: ToggleButtonGroupOnChange<T>;
}

function ToggleButtonGroupItem<T extends ToggleButtonGroupBaseValue>({
  value,
  selectedValue,
  disabled,
  i18nText,
  icon,
  iconClassName,
  onChange
}: ToggleButtonGroupItemProps<T>) {
  const handleClick = useCallback<() => void>(() => {
    onChange(value === selectedValue ? null : value);
  }, [value, selectedValue, onChange]);

  return (
    <PureIconButtonHelper
      className={cl(
        'border rounded text-sm flex items-center h-8 box-content',
        value === selectedValue
          ? 'border-blue-200 dark:border-transparent bg-blue-100 hover:bg-blue-50 text-black'
          : 'border-gray-300 dark:border-gray-700 hover:bg-gray-500/10 text-gray-700 dark:text-gray-300'
      )}
      i18nText={i18nText}
      i18nTextClassName="px-3 whitespace-nowrap"
      icon={icon}
      iconClassName={iconClassName}
      disabled={disabled}
      onClick={handleClick}
    />
  );
}

export default ToggleButtonGroupItem;
