import React, { useMemo, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  FetchLifestylesFilters,
  LifestyleClientID,
  ChangeLifestylesFiltersFunc
} from '../../../lifestylesTypes';

import { useLifestyleGammas } from '../../../hooks/useLifestyleGammas';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { CheckboxGroupFilter } from '../../../../../helpers/filters/CheckboxGroupFilter';
import { Translate } from '../../../../../helpers/Translate';

import { getLifestyleClientIdsCacheKeyPart } from '../../../utils/getLifestyleClientIdsCacheKeyPart';

import { categoriesKeys } from '../../../../../locales/keys';

import { LifestyleCache } from '../../../LifestyleCache';

import { LifestylesGammasFilterDataItem } from './LifestylesGammasFilter.types';

interface LifestylesGammasFilterProps {
  clientIds?: LifestyleClientID[];
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const name = 'gammaId' as const;

function LifestylesGammasFilter({
  clientIds,
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesGammasFilterProps) {
  const cacheKey = isEmpty(clientIds)
    ? LifestyleCache.gammasFilterCacheKey()
    : LifestyleCache.gammasLibraryFilterCacheKey(
        getLifestyleClientIdsCacheKeyPart({ clientIds })
      );

  const addInitialFilters = isEmpty(clientIds)
    ? undefined
    : { lifestyleClientIds: clientIds };

  const {
    lifestyleGammas,
    lifestyleGammasErrorMessage,
    lifestyleGammasFetched,
    lifestyleGammasIsPlaceholderData
  } = useLifestyleGammas({
    cacheKey,
    addInitialFilters
  });

  const lifestylesGammasFilterData = useMemo<
    LifestylesGammasFilterDataItem[]
  >(() => {
    return lifestyleGammas.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [lifestyleGammas]);

  const handleChange = useCallback<
    (
      changedFilters: { [name: string]: string[] | undefined },
      removeFilters: string[]
    ) => void
  >(
    (changedFilters, removeFilters) => {
      changeLifestylesFilters(
        { [name]: { in: changedFilters?.[name] || [] } },
        removeFilters
      );
    },
    [changeLifestylesFilters]
  );

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.gammas.singular} />
        </h6>
      </div>
      <AlertMessage message={lifestyleGammasErrorMessage} />
      <LoadingSkeleton
        loaded={lifestyleGammasIsPlaceholderData || lifestyleGammasFetched}
      >
        <CheckboxGroupFilter
          inputWrapperClassName="flex flex-col sm:flex-row sm:flex-wrap -mb-2"
          name={name}
          items={lifestylesGammasFilterData}
          value={lifestylesFilters?.[name]?.in as string[]}
          onChange={handleChange}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default LifestylesGammasFilter;
