import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { SelectedLifestyleUUID } from '../../../selectedLifestylesTypes';

import {
  DownloadSelectedLifestyleQueryResponse,
  DOWNLOAD_SELECTED_LIFESTYLE_QUERY
} from '../../../queries/downloadSelectedLifestyle.query';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';

import { useDownloadSelectedLifestyle } from '../../../hooks/useDownloadSelectedLifestyle';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { DownloadCache } from '../../../../downloads/DownloadCache';

interface DownloadSelectedLifestyleButtonProps {
  selectedLifestyleUuid: SelectedLifestyleUUID;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

function DownloadSelectedLifestyleButton({
  selectedLifestyleUuid,
  className,
  iconClassName,
  icon,
  i18nText,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton
}: DownloadSelectedLifestyleButtonProps) {
  const {
    downloadSelectedLifestyle,
    downloadSelectedLifestyleLoading,
    downloadSelectedLifestyleErrorMessage
  } = useDownloadSelectedLifestyle<DownloadSelectedLifestyleQueryResponse>({
    query: DOWNLOAD_SELECTED_LIFESTYLE_QUERY,
    cacheKeys: [DownloadCache.indexCacheKey()]
  });

  useShowToastOnErrorChange({ error: downloadSelectedLifestyleErrorMessage });

  const { downloadNanoId } = useDownloadNanoId();

  const handleClick = useCallback(() => {
    downloadSelectedLifestyle({
      uuid: selectedLifestyleUuid,
      deviceNanoId: downloadNanoId
    });
  }, [selectedLifestyleUuid, downloadNanoId, downloadSelectedLifestyle]);

  return (
    <PureTooltipIconButtonHelper
      className={className}
      icon={icon}
      iconClassName={iconClassName}
      i18nText={i18nText}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      tooltipSingleton={tooltipSingleton}
      disabled={downloadSelectedLifestyleLoading}
      onClick={handleClick}
    />
  );
}

export default DownloadSelectedLifestyleButton;
