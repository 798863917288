import React, { useCallback } from 'react';

import {
  FetchLifestylesFilters,
  ChangeLifestylesFiltersFunc
} from '../../../lifestylesTypes';

import { Checkbox } from '../../../../../helpers/Checkbox';
import { Translate } from '../../../../../helpers/Translate';

import { formsFields, words } from '../../../../../locales/keys';

interface LifestylesFavoriteCheckboxFilterProps {
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const name = 'favorite' as const;

function LifestylesFavoriteCheckboxFilter({
  lifestylesFilters,
  changeLifestylesFilters
}: LifestylesFavoriteCheckboxFilterProps) {
  const checked = lifestylesFilters?.[name];
  const handleClick = useCallback(() => {
    if (checked) {
      changeLifestylesFilters({}, [name]);
    } else {
      changeLifestylesFilters({ [name]: true });
    }
  }, [checked, changeLifestylesFilters]);

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={formsFields.favorites} />
        </h6>
      </div>
      <Checkbox
        checkboxWrapperClassName="flex items-center"
        i18nLabel={words.showFavoriteOnly}
        checked={checked}
        onChange={handleClick}
      />
    </div>
  );
}

export default LifestylesFavoriteCheckboxFilter;
