import React, { memo, useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import {
  SelectedLifestylesListItemLifestylesSet,
  SelectedLifestylesListItemSelectedLifestyle
} from './SelectedLifestylesListItem.types';
import {
  UpdateLifestylesSetCacheAction,
  FetchLifestylesSetsCacheKeys
} from '../../../../lifestylesSets/lifestylesSetsTypes';
import { OnSelectedLifestylesSidebarCloseAction } from '../../../../lifestyles/lifestylesTypes';
import {
  Checkable,
  OnSetCheckedIds
} from '../../../../../common/hooks/useTableCheckable';

import { SelectedLifestylesListItemPreview } from './components/SelectedLifestylesListItemPreview';
import { SelectedLifestylesListItemDeleteButton } from './components/SelectedLifestylesListItemDeleteButton';
import { LifestylesListItemNda } from '../../../../lifestyles/components/lists/LifestylesListItem/components/LifestylesListItemNda';
import { DownloadSelectedLifestyleButton } from '../../buttons/DownloadSelectedLifestyleButton';
import { LifestyleAttachModalButton } from '../../../../lifestyles/components/buttons/LifestyleAttachModalButton';

import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { TextWithTooltip } from '../../../../../helpers/TextWithTooltip';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { TooltipSingletonSourceWrapper } from '../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';
import { Checkbox } from '../../../../../helpers/Checkbox';

import { LifestylesPermissions } from '../../../../lifestyles/lifestylesConstants';
import { SelectedLifestylesPermissions } from '../../../selectedLifestylesConstants';

import { words } from '../../../../../locales/keys/words';

interface SelectedLifestylesListItemProps {
  lifestylesSet: SelectedLifestylesListItemLifestylesSet;
  selectedLifestyle: SelectedLifestylesListItemSelectedLifestyle;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  onLightboxOpen: OnLightboxOpenAction;
  onSelectedLifestylesSidebarClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<SelectedLifestylesListItemLifestylesSet>;
  checkable: Checkable;
  checked?: boolean;
  onCheck?: OnSetCheckedIds;
}

function SelectedLifestylesListItem({
  lifestylesSet,
  selectedLifestyle,
  lifestylesSetCacheKeys,
  onLightboxOpen,
  onSelectedLifestylesSidebarClose,
  updateLifestylesSetCache,
  checkable,
  checked,
  onCheck
}: SelectedLifestylesListItemProps) {
  const lifestyle = selectedLifestyle.lifestyle;

  const handleCheck = useCallback(
    () => onCheck(selectedLifestyle.id),
    [onCheck, selectedLifestyle.id]
  );

  return (
    <li className="flex items-center overflow-hidden space-x-3 group px-4 -mx-4">
      {checkable && <Checkbox checked={checked} onChange={handleCheck} />}

      <SelectedLifestylesListItemPreview
        selectedLifestyle={selectedLifestyle}
        onLightboxOpen={onLightboxOpen}
      />

      <div className="flex-1 overflow-hidden text-sm">
        <div className="font-medium truncate">
          <TextWithTooltip
            text={lifestyle?.name}
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
        <div className="dark:text-gray-500 text-gray-600 truncate">
          <LifestylesListItemNda lifestyle={lifestyle} />
          <TextWithTooltip
            text={lifestyle?.category?.localizedName}
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
      </div>

      <div className="flex-shrink-0 relative flex items-center justify-end">
        <TooltipSingletonSourceWrapper
          placement={TooltipPlacement.BOTTOM}
          withArrow
        >
          <CheckPermissions
            action={LifestylesPermissions.READ_LIFESTYLE_ATTACH_BUTTON}
          >
            <LifestyleAttachModalButton
              lifestyle={lifestyle}
              icon={IconsEnum.PAPER_CLIP_SOLID}
              tooltipI18nText={words.attach}
              iconClassName="h-5 w-5 stroke-1.75"
              className="dark:focus:ring-gray-100 dark:hover:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1.5 rounded-md text-gray-400 hover:text-gray-700 absolute focus:relative focus:opacity-100 opacity-0 group-hover:opacity-100 group-hover:relative"
            />
          </CheckPermissions>
          <CheckPermissions
            action={
              SelectedLifestylesPermissions.READ_SELECTED_LIFESTYLES_DOWNLOAD_BUTTON
            }
          >
            <DownloadSelectedLifestyleButton
              selectedLifestyleUuid={selectedLifestyle.uuid}
              className="dark:focus:ring-gray-100 dark:hover:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1.5 rounded-md text-gray-400 hover:text-gray-700 absolute focus:relative focus:opacity-100 opacity-0 group-hover:opacity-100 group-hover:relative"
              icon={IconsEnum.DOWNLOAD_OUTLINE}
              iconClassName="h-5 w-5"
              tooltipI18nText={words.download}
              tooltipSingleton
            />
          </CheckPermissions>
        </TooltipSingletonSourceWrapper>

        <SelectedLifestylesListItemDeleteButton
          selectedLifestyle={selectedLifestyle}
          lifestylesSet={lifestylesSet}
          lifestylesSetCacheKeys={lifestylesSetCacheKeys}
          className="-mr-2 dark:focus:ring-gray-100 dark:hover:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1.5 rounded-md text-gray-400 hover:text-gray-700 absolute focus:relative focus:opacity-100 opacity-0 group-hover:opacity-100 group-hover:relative"
          icon={IconsEnum.CROSS}
          iconClassName="h-5 w-5"
          onSelectedLifestylesSidebarClose={onSelectedLifestylesSidebarClose}
          updateLifestylesSetCache={updateLifestylesSetCache}
        />
      </div>
    </li>
  );
}

export default memo<SelectedLifestylesListItemProps>(
  SelectedLifestylesListItem
);
