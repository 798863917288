import { gql } from 'graphql-request';

import {
  LifestylesSetSelectedLifestylesUUID,
  LifestylesSetUUID
} from '../../lifestylesSetsTypes';

export interface EmptyLifestylesSetInput {
  clientMutationId?: string;
  uuid: LifestylesSetUUID;
}

export interface EmptyLifestylesSetError {
  fullMessages: string[] | null;
}

export interface EmptyLifestylesSetResponse {
  emptyLifestylesSet: {
    status: string;
    errors: EmptyLifestylesSetError;
  };
}

export interface EmptyLifestylesSetQueryResponse {
  uuid: LifestylesSetUUID;
  selectedLifestyles: {
    uuid: LifestylesSetSelectedLifestylesUUID;
  }[];
}

export const action = 'emptyLifestylesSet';

export const EMPTY_LIFESTYLES_SET_QUERY = gql`
  mutation EmptyLifestylesSet($uuid: ID!) {
    emptyLifestylesSet(input: { uuid: $uuid }) {
      status
      recordUuid
      record {
        uuid
        selectedLifestyles {
          uuid
        }
      }
      errors {
        fullMessages
      }
    }
  }
`;
