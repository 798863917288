import {
  FetchLifestyleCategoriesCacheKey,
  FetchFinLifestyleCategoriesFilters
} from '../../lifestylesTypes';

import {
  FetchLifestyleTonesQueryResponse,
  FETCH_LIFESTYLE_TONES_QUERY
} from '../../queries/fetchLifestyleTones.query';

import { useFinPaginatedCategories } from '../../../categories/hooks/useFinPaginatedCategories';

import { FetchCategoriesScopes } from '../../../categories/categoriesTypes';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.SCENE_TONES }
};
const options = {
  staleTime: 1000 * 60 * 60
};

interface LifestyleTonesDefaultOptions {
  cacheKey: FetchLifestyleCategoriesCacheKey;
  addInitialFilters?: FetchFinLifestyleCategoriesFilters;
}

function useLifestyleTones({
  cacheKey,
  addInitialFilters
}: LifestyleTonesDefaultOptions) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const {
    categories,
    categoriesError,
    categoriesFetched,
    categoriesIsPlaceholderData
  } = useFinPaginatedCategories<FetchLifestyleTonesQueryResponse>({
    cacheKey,
    query: FETCH_LIFESTYLE_TONES_QUERY,
    initialFilters,
    options
  });

  return {
    lifestyleTones: categories,
    lifestyleTonesErrorMessage: categoriesError,
    lifestyleTonesFetched: categoriesFetched,
    lifestyleTonesIsPlaceholderData: categoriesIsPlaceholderData
  };
}

export default useLifestyleTones;
