import React, { useMemo } from 'react';

import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';
import { IdFilterFields } from '../../../../../types';

import { useLifestyleTypes } from '../../../hooks/useLifestyleTypes';

import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { categoriesKeys } from '../../../../../locales/keys';

import { LifestyleCache } from '../../../LifestyleCache';

interface LifestylesTypesFilterBadgeItem {
  id: string;
  label: string;
}

function LifestylesTypesFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const { lifestyleTypes } = useLifestyleTypes({
    cacheKey: LifestyleCache.typesFilterCacheKey()
  });

  const LifestylesTypesFilterBadgeItems = useMemo<
    LifestylesTypesFilterBadgeItem[]
  >(() => {
    return lifestyleTypes.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [lifestyleTypes]);

  return (
    <ItemsFilterBadge
      filters={filters}
      i18nLabel={categoriesKeys.type}
      items={LifestylesTypesFilterBadgeItems}
      name={name}
      onRemoveFilter={onRemoveFilter}
      filterField={IdFilterFields.IN}
    />
  );
}

export default LifestylesTypesFilterBadge;
