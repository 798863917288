import React, { useCallback, useMemo } from 'react';
import cl from 'classnames';
import { contrast } from 'chroma-js';

import { IconsEnum } from '../../../../assets/icons/types';
import { IsDisabled } from '../../../../types';
import {
  ColorsSelectItemValue,
  ColorsSelectItemLabel,
  ColorsSelectItemColor,
  ColorsSelectOnToggleItem
} from '../../ColorsSelect.types';

import { PureTooltipIconButtonHelper } from '../../../buttons/PureTooltipIconButtonHelper';

const clearColor = 'Clear';
const multiColored = 'Multi-Colored';
const naturalColor = 'Natural';

interface ColorsSelectItemProps {
  value: ColorsSelectItemValue;
  label: ColorsSelectItemLabel;
  color: ColorsSelectItemColor;
  checked: boolean;
  disabled?: IsDisabled;
  onToggleItem: ColorsSelectOnToggleItem;
}

function ColorsSelectItem({
  value,
  label,
  color,
  checked,
  disabled,
  onToggleItem
}: ColorsSelectItemProps) {
  const handleClick = useCallback<() => void>(() => {
    onToggleItem(value);
  }, [value, onToggleItem]);

  const colorWithNatural = color === naturalColor ? '#a0522d' : color;
  const customColor = color === clearColor || color === multiColored;

  const style = useMemo<React.CSSProperties>(() => {
    return {
      backgroundColor: !customColor && colorWithNatural,
      backgroundImage:
        colorWithNatural === multiColored &&
        `linear-gradient(90deg,#b827fc,#2c90fc 25%,#b8fd33 50%,#fec837 75%,#fd1892)`
    };
  }, [colorWithNatural, customColor]);

  const iconClassName = cl(
    'h-5 w-5',
    { 'dark:text-white': value === '' },
    { 'dark:text-white text-black': value && customColor },
    {
      'text-white':
        value && !customColor && contrast(colorWithNatural, 'white') > 2,
      'text-black':
        value && !customColor && contrast(colorWithNatural, 'white') <= 2
    }
  );

  return (
    <PureTooltipIconButtonHelper
      className="p-2 h-8 w-full rounded flex items-center justify-center hover:opacity-75"
      style={style}
      tooltipSingleton
      tooltipText={label}
      icon={checked ? IconsEnum.CHECK : null}
      iconClassName={iconClassName}
      disabled={disabled}
      onClick={handleClick}
    />
  );
}

export default ColorsSelectItem;
