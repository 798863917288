import { useMemo, useCallback } from 'react';
import find from 'lodash/find';
import first from 'lodash/first';

import {
  FetchLifestylesFilters,
  ChangeLifestylesFiltersFunc
} from '../../lifestylesTypes';

import { useLifestyleCategories } from '../../hooks/useLifestyleCategories';

import { getChangeLifestylesFiltersCategoriesData } from '../../utils/getChangeLifestylesFiltersCategoriesData';

import { LifestyleCache } from '../../LifestyleCache';

interface UseLifestylesIndexPageBreadcrumbsProps {
  lifestylesFilters: FetchLifestylesFilters;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

function useLifestylesIndexPageBreadcrumbs({
  lifestylesFilters,
  changeLifestylesFilters
}: UseLifestylesIndexPageBreadcrumbsProps) {
  const cacheKey = LifestyleCache.categoriesCacheKey();

  const { lifestyleCategories } = useLifestyleCategories({ cacheKey });

  const currentParentCategory = useMemo(() => {
    const currentFiltersParentCategoryId = first(
      lifestylesFilters?.lifestyleParentCategoryIds
    );

    return (
      find(lifestyleCategories, ['id', currentFiltersParentCategoryId]) || null
    );
  }, [lifestyleCategories, lifestylesFilters]);

  const currentChildCategory = useMemo(() => {
    const currentFiltersChildCategoryId = first(
      lifestylesFilters?.lifestyleCategoryId?.in
    );

    return (
      find(lifestyleCategories, ['id', currentFiltersChildCategoryId]) || null
    );
  }, [lifestyleCategories, lifestylesFilters]);

  const handleResetCategoriesFilters = useCallback(() => {
    const { changeFilters, resetFilters } =
      getChangeLifestylesFiltersCategoriesData({
        lifestyleCategory: null,
        categories: []
      });

    changeLifestylesFilters(changeFilters, resetFilters);
  }, [changeLifestylesFilters]);

  const handleParentCategoryChangeFilters = useCallback(() => {
    const { changeFilters, resetFilters } =
      getChangeLifestylesFiltersCategoriesData({
        lifestyleCategory: currentParentCategory,
        categories: lifestyleCategories
      });

    changeLifestylesFilters(changeFilters, resetFilters);
  }, [changeLifestylesFilters, currentParentCategory, lifestyleCategories]);

  return {
    currentParentCategory,
    currentChildCategory,
    lifestyleCategories,
    handleResetCategoriesFilters,
    handleParentCategoryChangeFilters
  };
}

export default useLifestylesIndexPageBreadcrumbs;
