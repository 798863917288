import React, { memo } from 'react';

import { IsDisabled } from '../../../types';
import { FiltersProps, useFilters } from '../hooks/useFilters';

import {
  ColorsSelect,
  ColorsSelectItemValue,
  ColorsSelectDataItem
} from '../../ColorsSelect';

type ColorsSelectFilterValue = ColorsSelectItemValue[] | undefined;

interface ColorsSelectFilterProps
  extends FiltersProps<ColorsSelectFilterValue> {
  items: ColorsSelectDataItem[];
  disabled?: IsDisabled;
}

function ColorsSelectFilter({
  name,
  value,
  items,
  disabled,
  onChange
}: ColorsSelectFilterProps) {
  const { updatedValue, handleChange } = useFilters<ColorsSelectFilterValue>({
    name,
    value,
    emptyValue: [],
    onChange
  });

  return (
    <ColorsSelect
      isMulti
      items={items}
      value={updatedValue}
      disabled={disabled}
      onChange={handleChange}
    />
  );
}

export default memo(ColorsSelectFilter);
