import { useCallback } from 'react';
import compact from 'lodash/compact';

import { FetchLifestylesCacheKeys } from '../../../../../../lifestyles/lifestylesTypes';

import { FetchLifestylesSetsCacheKeys } from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import {
  TOGGLE_LIFESTYLE_FAVORITE_QUERY,
  ToggleLifestyleFavoriteQueryResponse
} from '../../../../../../lifestyles/queries/toggleLifestyleFavorite.query';

import { useToggleLifestyleFavorite } from '../../../../../../lifestyles/hooks/useToggleLifestyleFavorite';

import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import { LifestyleCache } from '../../../../../../lifestyles/LifestyleCache';
import { LifestylesSetCache } from '../../../../../../lifestylesSets/LifestylesSetCache';

import { SelectedLifestylesListItemLightboxFavoriteButtonSelectedLifestyle } from './SelectedLifestylesListItemLightboxFavoriteButton.types';

interface SelectedLifestylesListItemLightboxFavoriteButtonOptions {
  selectedLifestyle: SelectedLifestylesListItemLightboxFavoriteButtonSelectedLifestyle;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
}

function useSelectedLifestylesListItemLightboxFavoriteButton({
  selectedLifestyle,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys
}: SelectedLifestylesListItemLightboxFavoriteButtonOptions) {
  const {
    toggleLifestyleFavorite,
    toggleLifestyleFavoriteLoading,
    toggleLifestyleFavoriteErrorMessage
  } = useToggleLifestyleFavorite<ToggleLifestyleFavoriteQueryResponse>({
    query: TOGGLE_LIFESTYLE_FAVORITE_QUERY,
    cacheKeys: compact([
      LifestyleCache.indexCacheKey(),
      LifestylesSetCache.showCacheKey(),
      ...(lifestylesCacheKeys || []),
      ...(lifestylesSetCacheKeys || [])
    ])
  });

  const handleToggleFavorite = useCallback<() => void>(
    () => toggleLifestyleFavorite({ uuid: selectedLifestyle?.lifestyle?.uuid }),
    [selectedLifestyle, toggleLifestyleFavorite]
  );

  useShowToastOnErrorChange({ error: toggleLifestyleFavoriteErrorMessage });

  return {
    toggleLifestyleFavoriteLoading,
    handleToggleFavorite
  };
}

export default useSelectedLifestylesListItemLightboxFavoriteButton;
