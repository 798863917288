import React, { Fragment } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  FetchLifestylesCacheKeys,
  OnSelectedLifestylesSidebarCloseAction
} from '../../../../../../lifestyles/lifestylesTypes';

import {
  UpdateLifestylesSetCacheAction,
  FetchLifestylesSetsCacheKeys
} from '../../../../../../lifestylesSets/lifestylesSetsTypes';

import { SelectedLifestylesListItemDeleteButton } from '../../../SelectedLifestylesListItem/components/SelectedLifestylesListItemDeleteButton';

import { DownloadSelectedLifestyleButton } from '../../../../buttons/DownloadSelectedLifestyleButton';

import { SelectedLifestylesListItemLightboxFavoriteButton } from '../SelectedLifestylesListItemLightboxFavoriteButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import {
  SelectedLifestylesListItemLightboxBottomButtonsSelectedLifestyle,
  SelectedLifestylesListItemLightboxBottomButtonsLifestylesSet
} from './SelectedLifestylesListItemLightboxBottomButtons.types';

import { SelectedLifestylesPermissions } from '../../../../../selectedLifestylesConstants';

import { words } from '../../../../../../../locales/keys';

interface SelectedLifestylesListItemLightboxBottomButtonsProps {
  selectedLifestyle: SelectedLifestylesListItemLightboxBottomButtonsSelectedLifestyle;
  lifestylesSet: SelectedLifestylesListItemLightboxBottomButtonsLifestylesSet | null;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  onSelectedLifestylesSidebarClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<SelectedLifestylesListItemLightboxBottomButtonsLifestylesSet>;
}

function SelectedLifestylesListItemLightboxBottomButtons({
  selectedLifestyle,
  lifestylesSet,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys,
  onSelectedLifestylesSidebarClose,
  updateLifestylesSetCache
}: SelectedLifestylesListItemLightboxBottomButtonsProps) {
  return (
    <Fragment>
      <SelectedLifestylesListItemDeleteButton
        selectedLifestyle={selectedLifestyle}
        lifestylesSet={lifestylesSet}
        lifestylesSetCacheKeys={lifestylesSetCacheKeys}
        className="py-2 font-medium inline-flex space-x-1 items-center rounded-md px-4 text-gray-800 hover:bg-gray-300 bg-gray-200 px-4 focus:ring-white focus:ring-offset-black"
        i18nText={words.remove}
        onSelectedLifestylesSidebarClose={onSelectedLifestylesSidebarClose}
        updateLifestylesSetCache={updateLifestylesSetCache}
      />
      <CheckPermissions
        action={
          SelectedLifestylesPermissions.READ_SELECTED_LIFESTYLE_FAVORITE_BUTTON
        }
      >
        <SelectedLifestylesListItemLightboxFavoriteButton
          selectedLifestyle={selectedLifestyle}
          lifestylesCacheKeys={lifestylesCacheKeys}
          lifestylesSetCacheKeys={lifestylesSetCacheKeys}
        />
      </CheckPermissions>
      <CheckPermissions
        action={
          SelectedLifestylesPermissions.READ_SELECTED_LIFESTYLES_DOWNLOAD_BUTTON
        }
      >
        <DownloadSelectedLifestyleButton
          selectedLifestyleUuid={selectedLifestyle.uuid}
          className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white"
          icon={IconsEnum.DOWNLOAD_OUTLINE}
          iconClassName="h-6 w-6"
          tooltipSingleton
          tooltipI18nText={words.download}
        />
      </CheckPermissions>
    </Fragment>
  );
}

export default SelectedLifestylesListItemLightboxBottomButtons;
