import React, { useMemo, useState } from 'react';
import filter from 'lodash/filter';
import size from 'lodash/size';
import take from 'lodash/take';
import sortBy from 'lodash/sortBy';

import { ID } from '../../../../../../../types';

import {
  LifestylesFiltersNavBasePath,
  ChangeLifestylesFiltersFunc
} from '../../../../../lifestylesTypes';

import { LifestylesIndexPageCategoriesNavCategory } from '../../LifestylesIndexPageCategoriesNav.types';

import { LifestylesIndexPageCategoriesNavItem } from '../LifestylesIndexPageCategoriesNavItem';
import { LifestylesIndexPageCategoriesNavAmountButton } from '../LifestylesIndexPageCategoriesNavAmountButton';

interface LifestylesIndexPageCategoriesNavListProps {
  lifestyleCategories: LifestylesIndexPageCategoriesNavCategory[];
  parentCategoryId?: ID;
  filtersNavBasePath?: LifestylesFiltersNavBasePath;
  changeLifestylesFilters: ChangeLifestylesFiltersFunc;
}

const defaultListCategoriesSize = 8;

function LifestylesIndexPageCategoriesNavList({
  lifestyleCategories,
  parentCategoryId,
  filtersNavBasePath,
  changeLifestylesFilters
}: LifestylesIndexPageCategoriesNavListProps) {
  const listCategories = useMemo<LifestylesIndexPageCategoriesNavCategory[]>(
    () =>
      sortBy(
        filter(lifestyleCategories, (category) =>
          parentCategoryId
            ? category.parent?.id === parentCategoryId
            : !category.parent
        ),
        'localizedName'
      ),
    [lifestyleCategories, parentCategoryId]
  );

  const [showAll, setShowAll] = useState(false);

  if (size(listCategories) <= defaultListCategoriesSize) {
    return (
      <>
        {listCategories.map((category) => (
          <LifestylesIndexPageCategoriesNavItem
            key={category.id}
            category={category}
            categories={lifestyleCategories}
            filtersNavBasePath={filtersNavBasePath}
            changeLifestylesFilters={changeLifestylesFilters}
          />
        ))}
      </>
    );
  }

  const showedCategories = showAll
    ? listCategories
    : take(listCategories, defaultListCategoriesSize);

  return (
    <>
      {showedCategories.map((category) => (
        <LifestylesIndexPageCategoriesNavItem
          key={category.id}
          category={category}
          categories={lifestyleCategories}
          filtersNavBasePath={filtersNavBasePath}
          changeLifestylesFilters={changeLifestylesFilters}
        />
      ))}
      <LifestylesIndexPageCategoriesNavAmountButton
        showAll={showAll}
        setShowAll={setShowAll}
      />
    </>
  );
}

export default LifestylesIndexPageCategoriesNavList;
