import React, { useCallback } from 'react';
import includes from 'lodash/includes';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';

import {
  ColorsSelectDataItem,
  ColorsSelectItemValue,
  ColorsSelectOnChange,
  ColorsSelectOnToggleItem
} from './ColorsSelect.types';

import { ColorsSelectItem } from './helpers/ColorsSelectItem';

import { TooltipSingletonSourceWrapper } from '../tooltips/TooltipSingletonSourceWrapper';

import { TooltipPlacement } from '../tooltips/tooltipsConstants';

export interface ColorsSelectProps {
  items: ColorsSelectDataItem[];
  isMulti?: boolean;
  value?: ColorsSelectItemValue[];
  disabled?: boolean;
  onChange: ColorsSelectOnChange;
}

function ColorsSelect({
  items = [],
  isMulti = false,
  value = [],
  disabled,
  onChange
}: ColorsSelectProps) {
  const handleToggleItem = useCallback<ColorsSelectOnToggleItem>(
    (itemValue) => {
      if (!itemValue) {
        return onChange([]);
      }

      if (isMulti) {
        return onChange(
          includes(value, itemValue)
            ? without(value, itemValue)
            : [...value, itemValue]
        );
      }

      onChange([itemValue]);
    },
    [isMulti, onChange, value]
  );

  return (
    <div className="text-sm">
      <div className="grid grid-cols-auto-fill grid-cell-min-8 gap-1">
        <TooltipSingletonSourceWrapper
          placement={TooltipPlacement.TOP}
          withArrow
        >
          {items.map(({ value: itemValue, label, color }) => (
            <ColorsSelectItem
              key={itemValue}
              value={itemValue}
              label={label}
              color={color}
              checked={
                includes(value, itemValue) ||
                (itemValue === '' && isEmpty(value))
              }
              disabled={disabled}
              onToggleItem={handleToggleItem}
            />
          ))}
        </TooltipSingletonSourceWrapper>
      </div>
    </div>
  );
}

export default ColorsSelect;
