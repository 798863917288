import {
  FetchLifestyleCategoriesCacheKey,
  FetchFinLifestyleCategoriesFilters
} from '../../lifestylesTypes';

import {
  FetchLifestyleTypesQueryResponse,
  FETCH_LIFESTYLE_TYPES_QUERY
} from '../../queries/fetchLifestyleTypes.query';

import { useFinPaginatedCategories } from '../../../categories/hooks/useFinPaginatedCategories';

import { FetchCategoriesScopes } from '../../../categories/categoriesTypes';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.LIFESTYLES_TYPES }
};
const options = {
  staleTime: 1000 * 60 * 60
};

interface LifestyleTypesDefaultOptions {
  cacheKey: FetchLifestyleCategoriesCacheKey;
  addInitialFilters?: FetchFinLifestyleCategoriesFilters;
}

function useLifestyleTypes({
  cacheKey,
  addInitialFilters
}: LifestyleTypesDefaultOptions) {
  const initialFilters = addInitialFilters
    ? { ...defaultInitialFilters, ...addInitialFilters }
    : defaultInitialFilters;

  const {
    categories,
    categoriesError,
    categoriesFetched,
    categoriesIsPlaceholderData
  } = useFinPaginatedCategories<FetchLifestyleTypesQueryResponse>({
    cacheKey,
    query: FETCH_LIFESTYLE_TYPES_QUERY,
    initialFilters,
    options
  });

  return {
    lifestyleTypes: categories,
    lifestyleTypesErrorMessage: categoriesError,
    lifestyleTypesFetched: categoriesFetched,
    lifestyleTypesIsPlaceholderData: categoriesIsPlaceholderData
  };
}

export default useLifestyleTypes;
