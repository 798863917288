import { useCallback } from 'react';
import size from 'lodash/size';

import {
  UpdateLifestylesSetCacheAction,
  FetchLifestylesSetsCacheKey
} from '../../../lifestylesSets/lifestylesSetsTypes';

import { OnSelectedLifestylesSidebarCloseAction } from '../../../lifestyles/lifestylesTypes';

import { useEmptyLifestylesSet } from '../../../lifestylesSets/hooks/useEmptyLifestylesSet';

import { useShowToastOnErrorChange } from '../../../../common/hooks/useShowToastOnErrorChange';

import { LifestylesSetCache } from '../../../lifestylesSets/LifestylesSetCache';

import {
  SelectedLifestylesFloatingCounterLifestylesSet,
  SelectedLifestylesFloatingCounterOnLifestylesSelect
} from './SelectedLifestylesFloatingCounter.types';

interface UseLifestylesIndexPageSelectedLifestylesOptions {
  lifestylesSet: SelectedLifestylesFloatingCounterLifestylesSet;
  lifestylesSetCacheKey?: FetchLifestylesSetsCacheKey;
  onClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<SelectedLifestylesFloatingCounterLifestylesSet>;
  onLifestylesSelect?: SelectedLifestylesFloatingCounterOnLifestylesSelect;
}

function useSelectedLifestylesFloatingCounter({
  lifestylesSet,
  lifestylesSetCacheKey,
  onClose,
  updateLifestylesSetCache,
  onLifestylesSelect
}: UseLifestylesIndexPageSelectedLifestylesOptions) {
  const uuid = lifestylesSet?.uuid;

  const {
    emptyLifestylesSetLoading,
    emptyLifestylesSetErrorMessage,
    emptyLifestylesSet
  } = useEmptyLifestylesSet({
    itemCacheKey: lifestylesSetCacheKey || LifestylesSetCache.showCacheKey(),
    updateLifestylesSetCache
  });

  const handleEmptyLifestylesSet = useCallback(() => {
    uuid && emptyLifestylesSet({ uuid }).then(() => onLifestylesSelect?.([]));
    onClose();
  }, [uuid, emptyLifestylesSet, onClose, onLifestylesSelect]);

  useShowToastOnErrorChange({ error: emptyLifestylesSetErrorMessage });

  const selectedLifestylesCount = size(lifestylesSet?.selectedLifestyles);

  return {
    selectedLifestylesCount,
    emptyLifestylesSetLoading,
    handleEmptyLifestylesSet
  };
}

export default useSelectedLifestylesFloatingCounter;
