import {
  CreatedAt,
  CreatedAtFilter,
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  DeleteItemGqlQuery,
  DeleteItemGqlStatus,
  FetchItemCacheKey,
  FetchItemGqlQuery,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  ItemGqlError,
  UpdatedAt,
  DeletedAt,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  UUID,
  UUIDFilter,
  NanoIDFilter,
  DeviceNanoId,
  DeleteItemCacheKey,
  IdFilterType
} from '../../types';

import {
  LifestyleID,
  LifestyleUUID,
  LifestyleName,
  LifestyleProjectNameUUID,
  LifestyleProjectNameName,
  LifestyleCategoryUUID,
  LifestyleCategoryName,
  LifestyleImageUUID,
  LifestyleImageFile,
  LifestyleNda,
  LifestyleBlocked,
  LifestyleFavorite,
  LifestyleCategoryLocalizedName,
  LifestyleAuthorLocalizedName,
  LifestyleNanoID,
  LifestylePremiumContentAt
} from '../lifestyles/lifestylesTypes';
import {
  UserFullName,
  UserImageFile,
  UserImageUUID,
  UserNanoID,
  UserClient,
  UserCurrentTeamNanoID
} from '../users/usersTypes';

export type SelectedLifestyleID = ID;
export type SelectedLifestyleUUID = UUID;
export type SelectedLifestyleCreatedAt = CreatedAt;
export type SelectedLifestyleUpdatedAt = UpdatedAt;
export type SelectedLifestyleDeletedAt = DeletedAt;

export type SelectedLifestyleDeviceNanoId = DeviceNanoId;

export type SelectedLifestyleUserNanoId = UserNanoID;
export type SelectedLifestyleUserFullName = UserFullName;
export type SelectedLifestyleUserImageUUID = UserImageUUID;
export type SelectedLifestyleUserImageFile = UserImageFile;
export type SelectedLifestyleUserClient = UserClient;
export type SelectedLifestyleUserCurrentTeamNanoID = UserCurrentTeamNanoID;

export type SelectedLifestyleLifestyleID = LifestyleID;
export type SelectedLifestyleLifestyleUUID = LifestyleUUID;
export type SelectedLifestyleLifestyleNanoID = LifestyleNanoID;
export type SelectedLifestyleLifestyleName = LifestyleName;
export type SelectedLifestyleLifestyleNda = LifestyleNda;
export type SelectedLifestyleLifestylePremiumContentAt =
  LifestylePremiumContentAt;
export type SelectedLifestyleLifestyleBlocked = LifestyleBlocked;
export type SelectedLifestyleLifestyleFavorite = LifestyleFavorite;

export type SelectedLifestyleLifestyleAuthorLocalizedName =
  LifestyleAuthorLocalizedName;

export type SelectedLifestyleLifestyleProjectNameUUID =
  LifestyleProjectNameUUID;
export type SelectedLifestyleLifestyleProjectNameName =
  LifestyleProjectNameName;

export type SelectedLifestyleLifestyleCategoryUUID = LifestyleCategoryUUID;
export type SelectedLifestyleLifestyleCategoryName = LifestyleCategoryName;
export type SelectedLifestyleLifestyleCategoryLocalizedName =
  LifestyleCategoryLocalizedName;

export type SelectedLifestyleLifestyleImageUUID = LifestyleImageUUID;
export type SelectedLifestyleLifestyleImageFile = LifestyleImageFile;

export interface FetchSelectedLifestylesFilters {
  id?: IdFilterType;
  taskUuid?: UUIDFilter;
  taskNanoId?: NanoIDFilter;
  projectUuid?: UUIDFilter;
  projectNanoId?: NanoIDFilter;
  createdAt?: CreatedAtFilter;
}

export enum FetchSelectedLifestylesSortableFields {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export enum FetchSelectedLifestylesSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchSelectedLifestylesGqlQuery = FetchItemsGqlQuery;
export type FetchSelectedLifestyleGqlQuery = FetchItemGqlQuery;
export type CreateSelectedLifestyleGqlQuery = CreateItemGqlQuery;
export type UpdateSelectedLifestyleGqlQuery = UpdateItemGqlQuery;
export type DeleteSelectedLifestyleGqlQuery = DeleteItemGqlQuery;
export type DownloadSelectedLifestyleGqlQuery = UpdateItemGqlQuery;

export type CreateSelectedLifestyleGqlStatus = CreateItemGqlStatus;
export type UpdateSelectedLifestyleGqlStatus = UpdateItemGqlStatus;
export type DeleteSelectedLifestyleGqlStatus = DeleteItemGqlStatus;
export type DownloadSelectedLifestyleGqlStatus = UpdateItemGqlStatus;
export type SelectedLifestyleGqlError = ItemGqlError;

export type FetchSelectedLifestylesCacheKey = FetchItemsCacheKey;
export type FetchSelectedLifestyleCacheKey = FetchItemCacheKey;

export type FetchSelectedLifestylesFetched = FetchItemsFetched;
export type FetchSelectedLifestylesErrorMessage = FetchItemsErrorMessage;
export type FetchSelectedLifestylesIsPlaceholderData =
  FetchItemsIsPlaceholderData;
export type FetchSelectedLifestylesPage = FetchItemsPage;
export type FetchSelectedLifestylesLimit = FetchItemsLimit;
export type FetchSelectedLifestylesSort = FetchSelectedLifestylesSortTypes[];
export type FetchSelectedLifestylesTotalCount = FetchItemsTotalCount;
export type FetchSelectedLifestylesSortSelectedLifestyles = FetchItemsSortItems;
export type FetchSelectedLifestylesPaginateSelectedLifestyles =
  FetchItemsPaginateItems;
export type FetchSelectedLifestylesPrefetchSelectedLifestyles =
  FetchItemsPrefetchItems;
export type FetchSelectedLifestylesShowSelectedLifestyle = FetchItemsShowItem;
export type FetchSelectedLifestylesEditSelectedLifestyle = FetchItemsEditItem;

export type DeleteSelectedLifestyleCacheKey = DeleteItemCacheKey;
export type DeleteSelectedLifestyleCacheKeys =
  DeleteSelectedLifestyleCacheKey[];
