import { FetchItemCacheKey, FetchItemsGqlQuery } from '../../../../types';

import {
  FetchCategoriesGqlQuery,
  FetchCategoriesCacheKey,
  FetchFinCategoriesFilters,
  FetchCategoriesSort,
  FetchCategoriesPage,
  FetchCategoriesLimit
} from '../../categoriesTypes';

import { InfiniteIndexQueryBaseNodeType } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

import { useFinInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useFinInfiniteIndexQuery';

import {
  INITIAL_CATEGORIES_FILTERS,
  INITIAL_CATEGORIES_LIMIT,
  INITIAL_CATEGORIES_SORT
} from '../../categoriesConstants';

interface CategoriesDefaultOptions {
  cacheKey: FetchCategoriesCacheKey;
  query: FetchCategoriesGqlQuery;
  initialFilters?: FetchFinCategoriesFilters;
  initialSort?: FetchCategoriesSort;
  initialPage?: FetchCategoriesPage;
  initialLimit?: FetchCategoriesLimit;
  options?: {
    cacheTime?: number;
    staleTime?: number;
  };
}

interface CategoriesWithPrefetchItemOptions {
  fetchItemCacheKey: FetchItemCacheKey;
  fetchItemQuery: FetchItemsGqlQuery;
}

interface CategoriesWithoutPrefetchItemOptions {
  fetchItemCacheKey?: never;
  fetchItemQuery?: never;
}

type CategoriesOptions = CategoriesDefaultOptions &
  (CategoriesWithPrefetchItemOptions | CategoriesWithoutPrefetchItemOptions);

const scope = 'categories';

function useFinInfiniteCategories<
  ItemType extends InfiniteIndexQueryBaseNodeType
>({
  cacheKey,
  query,
  initialLimit = INITIAL_CATEGORIES_LIMIT,
  initialFilters = INITIAL_CATEGORIES_FILTERS,
  initialSort = INITIAL_CATEGORIES_SORT,
  options = {}
}: CategoriesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    clearItemsFilters,
    filterItems,
    sortItems,
    limitItems,
    isFetchingNextPage,
    hasNextPage,
    loadMoreItems,
    changeItemsFilters
  } = useFinInfiniteIndexQuery<ItemType>({
    cacheKey,
    initialFilters,
    initialSort,
    initialLimit,
    options,
    query,
    scope
  });

  return {
    categoriesData: data,
    categories: items,
    categoriesError: itemsError,
    categoriesTotalCount: itemsTotalCount,
    categoriesFetched: isFetched,
    categoriesLoading: isLoading,
    categoriesIsPlaceholderData: isPlaceholderData,
    categoriesFilters: currentFilters,
    categoriesSort: currentSort,
    categoriesPage: currentPage,
    categoriesLimit: currentLimit,
    filterCategories: filterItems,
    clearCategoriesFilters: clearItemsFilters,
    sortCategories: sortItems,
    limitCategories: limitItems,
    hasNextCategoriesPage: hasNextPage,
    isFetchingNextCategoriesPage: isFetchingNextPage,
    loadMoreCategories: loadMoreItems,
    changeCategoriesFilters: changeItemsFilters
  };
}

export default useFinInfiniteCategories;
