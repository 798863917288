import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

import {
  DownloadSelectedLifestyleGqlQuery,
  DownloadSelectedLifestyleGqlStatus,
  SelectedLifestyleDeviceNanoId,
  SelectedLifestyleGqlError,
  SelectedLifestyleUUID
} from '../../selectedLifestylesTypes';

interface DownloadSelectedLifestyleOptions {
  query: DownloadSelectedLifestyleGqlQuery;
  cacheKeys?: string[];
}

export interface DownloadSelectedLifestyleResponse<
  DownloadSelectedLifestyleRecordType
> {
  downloadSelectedLifestyle: {
    status: DownloadSelectedLifestyleGqlStatus;
    recordUuid: SelectedLifestyleUUID;
    record: DownloadSelectedLifestyleRecordType;
    errors: DownloadSelectedLifestyleError;
  };
}

export interface DownloadSelectedLifestyleInput {
  uuid: SelectedLifestyleUUID;
  deviceNanoId: SelectedLifestyleDeviceNanoId;
}

export interface DownloadSelectedLifestyleError {
  fullMessages: SelectedLifestyleGqlError;
}

const action = 'downloadSelectedLifestyle';

function useDownloadSelectedLifestyle<DownloadSelectedLifestyleRecordType>({
  query,
  cacheKeys
}: DownloadSelectedLifestyleOptions) {
  const {
    updateQuery,
    updateQueryReset,
    updateQueryData,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadSelectedLifestyleInput,
    DownloadSelectedLifestyleResponse<DownloadSelectedLifestyleRecordType>,
    DownloadSelectedLifestyleError,
    DownloadSelectedLifestyleRecordType
  >({ action, query, cacheKeys });

  return {
    downloadSelectedLifestyleData: updateQueryData,
    downloadSelectedLifestyleError: updateQueryError,
    downloadSelectedLifestyleLoading: updateQueryLoading,
    downloadSelectedLifestyleErrorMessage: updateQueryErrorMessage,
    downloadSelectedLifestyle: updateQuery,
    downloadSelectedLifestyleReset: updateQueryReset
  };
}

export default useDownloadSelectedLifestyle;
