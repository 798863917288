import { gql } from 'graphql-request';

import { SelectedLifestyleUUID } from '../selectedLifestylesTypes';

export interface DownloadSelectedLifestyleQueryResponse {
  uuid: SelectedLifestyleUUID;
}

export const DOWNLOAD_SELECTED_LIFESTYLE_QUERY = gql`
  mutation DownloadSelectedLifestyle($uuid: ID!, $deviceNanoId: String!) {
    downloadSelectedLifestyle(
      input: { uuid: $uuid, deviceNanoId: $deviceNanoId }
    ) {
      recordUuid
      status
      record {
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
